import React from 'react';
import './Serviços.css';
import MainBtn from '../MainBtn/MainBtn';

function Serviços() {
  return (
    <div className='serviços-wrapper'>
      <div className='serviços-container'>
        <div className='serviços-content'>
          <h2>Serviços</h2>
          <p>Descrição do Serviços.</p>
          <p>Mais informações sobre o Serviços.</p>
          <MainBtn />
        </div>
        <div className='serviços-img'>
        </div>
      </div>
    </div>
  );
}

export default Serviços;
