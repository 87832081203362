import React, { useRef, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Header from './components/shared/Header';
import Footer from './components/shared/Footer';
import Hero from './components/hero/Hero';
import menuItems from './components/shared/data/menuItems';
import socialLinks from './components/shared/data/socialLinks';
import handleNavigationBtn from './components/shared/utils/handleNavigationBtn';

import './App.css';
import './assets/styles/Reset.css';

import Inicio from './components/inicio/Inicio';
import Quemsoueu from './components/quemsoueu/Quemsoueu';  // Ensure no spaces in import paths
import Serviços from './components/serviços/Serviços';   // Use consistent path formatting
import Cursos from './components/cursos/Cursos';
import Contato from './components/contato/Contato';
import Newsletter from './components/newsletter/Newsletter';

function App() {
  const main = process.env.REACT_APP_MAIN;
  const navigate = useNavigate();
  const location = useLocation();
  const [refs, setRefs] = useState({});

  useEffect(() => {
    const setupRefs = () => {
      const newRefs = {};
      menuItems.forEach((item) => {
        if (item.type === 'Scroll') {
          newRefs[item.to] = React.createRef();
        }
      });
      setRefs(newRefs);
    };
    setupRefs();
  }, [menuItems]);

  const handleNavigation = async (item) => {
    await handleNavigationBtn(item, refs, navigate, location);
  };

  return (
    <div className='App'>
      <Header
        handleNavigationBtn={handleNavigation}
        menuItems={menuItems}
        socialLinks={socialLinks}
      />
      <Routes>
        <Route
          path={main}
          element={
            <>
              <div className='body-wrapper'>
                <Hero />
                <div ref={refs.inicio}><Inicio /></div>
                <div ref={refs.quemsoueu}><Quemsoueu /></div>  {/* Use camelCase for refs */}
                <div ref={refs.servicos}><Serviços /></div>
                <div ref={refs.cursos}><Cursos /></div>
                <div ref={refs.contato}><Contato /></div>
                <Newsletter />
              </div>
            </>
          }
        />
      </Routes>
      <Footer
        handleNavigationBtn={handleNavigation}
        menuItems={menuItems}
        socialLinks={socialLinks}
      />
      <div className='whats-btn'>
        <a href='https://wa.me/5584996060201' target='_blank' rel='noreferrer'>
          <i className='uil uil-whatsapp whats'></i>
        </a>
      </div>
    </div>
  );
}

export default App;
