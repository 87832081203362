const socialLinks = [
    {
        url: 'https://luanasalmito.com/',
        label: 'Linkedin icon',
        icon: 'uil uil-linkedin-alt',
    },
    {
        url: 'https://luanasalmito.com/',
        label: 'Instagram icon',
        icon: 'uil uil-instagram',
    },
    {
        url: 'https://luanasalmito.com/',
        label: 'Whatsapp Icon',
        icon: 'uil uil-whatsapp',
    },
    {
        url: 'https://luanasalmito.com/',
        label: 'Facebook icon',
        icon: 'uil uil-facebook-f',
    },
];

export default socialLinks;

