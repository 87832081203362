import React from 'react';
import './Cursos.css';
import MainBtn from '../MainBtn/MainBtn';

function Cursos() {
  return (
    <div className='cursos-wrapper'>
      <div className='cursos-container'>
        <div className='cursos-content'>
          <h2>Cursos</h2>
          <p>Descrição do Cursos.</p>
          <p>Mais informações sobre o Cursos.</p>
          <MainBtn />
        </div>
        <div className='cursos-img'>
        </div>
      </div>
    </div>
  );
}

export default Cursos;
