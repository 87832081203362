import React from 'react';
import './Inicio.css';
import MainBtn from '../MainBtn/MainBtn';

function Inicio() {
  return (
    <div className='inicio-wrapper'>
      <div className='inicio-container'>
        <div className='inicio-content'>
          <h2>Inicio</h2>
          <p>Descrição do Inicio.</p>
          <p>Mais informações sobre o Inicio.</p>
          <MainBtn />
        </div>
        <div className='inicio-img'>
        </div>
      </div>
    </div>
  );
}

export default Inicio;
