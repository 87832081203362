import React from 'react';
import './Contato.css';
import MainBtn from '../MainBtn/MainBtn';

function Contato() {
  return (
    <div className='contato-wrapper'>
      <div className='contato-container'>
        <div className='contato-content'>
          <h2>Contato</h2>
          <p>Descrição do Contato.</p>
          <p>Mais informações sobre o Contato.</p>
          <MainBtn />
        </div>
        <div className='contato-img'>
        </div>
      </div>
    </div>
  );
}

export default Contato;
