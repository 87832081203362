const Link = 'Link';
const Scroll = 'Scroll';

const menuItems = [
  {
    label: 'Inicio',
    type: Scroll,
    to: 'inicio',
  },
  {
    label: 'Quem sou eu',
    type: Scroll,
    to: 'quem sou eu',
  },
  {
    label: 'Serviços',
    type: Scroll,
    to: 'serviços',
  },
  {
    label: 'Cursos',
    type: Scroll,
    to: 'cursos',
  },
  {
    label: 'Contato',
    type: Scroll,
    to: 'contato',
  },
];

export default menuItems;

