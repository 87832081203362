import React from 'react';
import './Newsletter.css';
import MainBtn from '../MainBtn/MainBtn';

function Newsletter() {
  return (
    <div className='newsletter-wrapper'>
      <div className='newsletter-container'>
        <div className='newsletter-content'>
          <h2>Newsletter</h2>
          <p>Descrição do Newsletter.</p>
          <p>Mais informações sobre o Newsletter.</p>
          <MainBtn />
        </div>
        <div className='newsletter-img'>
        </div>
      </div>
    </div>
  );
}

export default Newsletter;
