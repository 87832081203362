import React from "react";
import "./Footer.css";

const Footer = ({ handleNavigationBtn, menuItems, socialLinks }) => {
  return (
    <footer className="footer-background">
      <div className="footer-container">
        <div className="footer-logo">
          <h1>LOGO</h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </p>
        </div>

        <div className="footer-item">
          <h5>NAVEGAÇÃO</h5>
          <div>
            {menuItems.map((item, index) => (
              <li key={index}>
                <button onClick={() => handleNavigationBtn(item)}>
                  {item.label}
                </button>
              </li>
            ))}
          </div>
        </div>

        <div className="footer-item">
          <h5>ÁREAS E ESPECIALIDADES</h5>
          <div className="especialidades">
            <p>Lorem Ipsum is simply</p>
            <p>Lorem Ipsum is simply</p>
            <p>Lorem Ipsum is simply</p>
            <p>Lorem Ipsum is simply</p>
            <p>Lorem Ipsum is simply</p>
          </div>
        </div>

        <div className="footer-item">
          <div>
            <h5>CONTATO</h5>
            <p>Email: contato@empresa.com</p>
            <p>Telefone: (84) 99999-9999</p>
          </div>
          <div>
            <h6>ENDEREÇO</h6>
            <p> TCC - Tirol Conference Citadel, Sala 403</p>
            <p>Tirol, Natal, CEP: 59111-111</p>
          </div>
          <div>
            <h6>SUPORTE</h6>
            <p>Email: suporte@empresa.com</p>
            <p>Telefone: (84) 88888-8888</p>
          </div>
        </div>
      </div>

      <div className="footer-copy">
        <p>&copy; 2024 Sua Empresa. Todos os direitos reservados.</p>
        <div className="footer-socials">
          {socialLinks.map((link, index) => (
            <a key={index} href={link.url} target="_blank" rel="noreferrer">
              <i className={`${link.icon}`} aria-label={link.label}></i>
            </a>
          ))}
        </div>
      </div>
    </footer>
  );
};

export default Footer;


