import scrollToComponent from './scrollToComponent';

const handleNavigationBtn = async (item, refs, navigate, location) => {
    if (item.type === 'Link') {
        navigate(item.to);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    } else if (item.type === 'Scroll') {
        if (location.pathname !== '/') {
            await navigate('/');
        }
        scrollToComponent(refs, item.to);
    }
};

export default handleNavigationBtn;

