import React from 'react';
import './Hero.css';
import MainBtn from '../MainBtn/MainBtn';

const Hero = () => {
  return (
    <div className='hero-wrapper'>
      <div className='hero-overlay'></div>
      <div className='hero-container'>
        <div className='hero-content'>
          <div>
            <h1>Lorem Ipsum</h1>
            <h2>The standard Lorem Ipsum passage</h2>
          </div>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>

          <MainBtn />
        </div>

        <div className='hero-img'></div>
      </div>
    </div>
  );
};

export default Hero;

